<template>
  <section>
    <section class="ConOfproductsHead">
      <div class="movingHead"></div>
      <div class="conOfprodHeadTxt">
        <h1>
          Keep Active and Eat Healthy to Improve Well-being and Feel Great
        </h1>
        <div class="mt-2">
          Eating foods that are good for you and staying physically active may
          help you reach and maintain a healthy weight and improve how you feel.
        </div>
        <div class="mt-2">
          You also may find that moving more and eating better could help you
          keep up with the demands of your busy life and be there for the people
          who depend on you.
        </div>
      </div>
    </section>
    <v-row class="h-100 py-5 ma-0">
      <v-col md="7" cols="12">
        <div class="conOfheadTxtRev">
          <div class="titleTxtpurple py-2 px-2">
            Why should I move more and eat better?
          </div>

          <div class="conOfline d-flex jusify-center">
            <span class="smallthinLine"></span>

            <span class="thickLine"></span>
            <span class="thinLine"></span>
          </div>
        </div>
      </v-col>
    </v-row>
    <section class="stopping-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec">
          <v-row class="justify-space-around py-5 px-2">
            <v-col md="6" cols="12" class="d-flex justify-center align-center">
              <v-row class="conOfMoveMore">
                <v-col cols="12">
                  <div class="uPainTxtHead">
                    In addition to helping you reach and maintain a healthy
                    weight, staying active and eating better may lower your
                    chances of developing
                  </div>
                </v-col>

                <v-col md="6" cols="12">
                  <div class="moveTxt mb-3">
                    Type 2 diabetes, or high blood sugar
                  </div>
                  <div class="moveTxt mb-3">High blood pressure</div>
                  <div class="moveTxt mb-3">kidney disease</div>
                </v-col>
                <v-col md="6" cols="12">
                  <div class="moveTxt mb-3">Heart disease</div>
                  <div class="moveTxt mb-3">Stroke</div>
                  <div class="moveTxt mb-3">Certain kinds of cancer</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5" cols="12" class="d-flex justify-center">
              <img src="@/assets/img/body2.png" class="img-fluid" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="stopping-sec mt-5">
      <v-container class="h-100">
        <div class="conOfsideSec">
          <v-row class="justify-space-around py-5 px-2">
            <v-col md="6" cols="12" class="d-flex justify-center align-center">
              <v-row class="conOfMoveMore">
                <v-col cols="12">
                  <div class="uPainTxtHead">
                    But improving your health isn’t the only reason to move more
                    and eat better. You may also
                  </div>
                </v-col>

                <v-col md="6" cols="12">
                  <div class="moveTxt mb-3">
                    Have more energy for work, play, and family
                  </div>
                  <div class="moveTxt mb-3">Feel better about yourself</div>
                  <div class="moveTxt mb-3">Manage stress better</div>
                </v-col>
                <v-col md="6" cols="12">
                  <div class="moveTxt mb-3">
                    Set a good example for your children, friends, and other
                    family members
                  </div>
                  <div class="moveTxt mb-3">
                    Tone your body—without losing your curves
                  </div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5" cols="12" class="d-flex justify-center align-center">
              <img src="@/assets/img/healthy.svg" class="img-fluid" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="stopping-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec">
          <v-row class="justify-space-around py-5 px-2">
            <v-col md="6" cols="12" class="d-flex justify-center align-center">
              <v-row class="conOfMoveMore">
                <v-col cols="12">
                  <div class="uPainTxtHead">
                    Being active is good for your mind and body. Every minute of
                    activity counts – and the more you do, the more you'll
                    benefit.
                    <br />
                    Exercise can:
                  </div>
                </v-col>

                <v-col md="6" cols="12">
                  <div class="moveTxt mb-3">Improve your sleep</div>
                  <div class="moveTxt mb-3">Clear your mind</div>
                  <div class="moveTxt mb-3">Help with back or joint pain</div>
                </v-col>
              </v-row>
            </v-col>
            <v-col md="5" cols="12" class="d-flex justify-center align-center">
              <img src="@/assets/img/sleep.svg" class="img-fluid" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <!-- <v-row class="conOfMoveMore">
      <v-col cols="12">
        <div class="uPainTxtHead">
          But improving your health isn’t the only reason to move more and eat
          better. You may also
        </div>
      </v-col>

      <v-col md="6" cols="12">
        <div class="moveTxt mb-3">
          Have more energy for work, play, and family
        </div>
        <div class="moveTxt mb-3">Feel better about yourself</div>
        <div class="moveTxt mb-3">Manage stress better</div>
      </v-col>
      <v-col md="6" cols="12">
        <div class="moveTxt mb-3">
          Set a good example for your children, friends, and other family
          members
        </div>
        <div class="moveTxt mb-3">
          Tone your body—without losing your curves
        </div>
      </v-col>
    </v-row>
    <v-row class="conOfMoveMore">
      <v-col cols="12">
        <div class="uPainTxtHead">
          Being active is good for your mind and body. Every minute of activity
          counts – and the more you do, the more you'll benefit.
          <br />
          Exercise can:
        </div>
      </v-col>

      <v-col md="6" cols="12">
        <div class="moveTxt mb-3">Improve your sleep</div>
        <div class="moveTxt mb-3">Clear your mind</div>
        <div class="moveTxt mb-3">Help with back or joint pain</div>
      </v-col>
    </v-row> -->
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfsideSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">
                How much physical activity do I need?
              </p>
              <div class="uPainsubTxt my-2">
                To maintain or improve your health, aim for 150 minutes per
                week—or at least 30 minutes on all or most days of the week—of
                moderate physical activity. If you haven’t been active, work
                slowly toward the goal of 150 minutes per week. For example,
                start out doing light or moderate activities for shorter amounts
                of time throughout the week. You can gain some health benefits
                even if you do as little as 60 minutes of moderate physical
                activity a week.
              </div>
              <div class="uPainsubTxt my-2">
                For best results, spread out your physical activity throughout
                the week. Even 10 or 15 minutes at a time counts. And any amount
                of physical activity is better than none at all.
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img src="@/assets/img/time.png" class="img-fluid w-75" />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">How can I eat healthier?</p>
              <div class="uPainsubTxt my-2">
                An example of a healthy meal includes vegetables, fruits, and
                small portions of protein and whole grains. These foods provide
                fiber and important nutrients such as vitamins and minerals.
                When planning meals for you and your family, think about
                including
                <ul>
                  <li>
                    a salad or other different-colored vegetables, such as
                    spinach; sweet potatoes; and red, green, orange, or yellow
                    peppers
                  </li>
                  <li>
                    fat-free or low-fat milk and milk products, or nondairy
                    products such as almond or rice milk
                  </li>
                  <li>
                    different-colored fruits, including apples, bananas, and
                    grapes
                  </li>
                  <li>
                    lean beef, pork, or other protein foods, such as chicken,
                    seafood, eggs, or beans
                  </li>
                  <li>
                    whole grains such as brown rice, oatmeal, whole-wheat bread,
                    and whole-grain cornmeal
                  </li>
                </ul>
                Limit sweet treats to special occasions, and keep portions
                small. Have one cookie or piece of candy, rather than trying
                every kind.
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img
                src="@/assets/img/food.jpg"
                class="img-fluid w-75 img-border"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <v-row class="h-100 py-5 ma-0">
      <v-col md="5" cols="12">
        <div class="conOfheadTxtRev">
          <div class="titleTxtpurple py-2 px-2">9 simple tips to move more</div>

          <div class="conOfline d-flex jusify-center">
            <span class="smallthinLine"></span>

            <span class="thickLine"></span>
            <span class="thinLine"></span>
          </div>
        </div>
      </v-col>
    </v-row>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfsideSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">1.Get into a good habit</p>
              <div class="uPainsubTxt my-2">
                Set a reminder (you could use the alarm or timer on your phone)
                to get up and move every 30 minutes during the day. Try
                stretching during TV ad breaks or pacing around the kitchen
                while the kettle's boiling.
              </div>
              <p class="uPainTxtHead my-2">2.Leave for lunch</p>
              <div class="uPainsubTxt my-2">
                Lunchtime is a good opportunity to fit in some activity. Take a
                break to stretch and unwind, or even fit in a quick 10-minute
                home workout.
              </div>
              <p class="uPainTxtHead my-2">3.Your own stand-up routine</p>
              <div class="uPainsubTxt my-2">
                Try standing when you can instead of sitting – even short
                periods will add up and improve your strength.
              </div>
              <p class="uPainTxtHead my-2">4.Take up a hobby</p>
              <div class="uPainsubTxt my-2">
                Active hobbies like gardening can be great for your mind as well
                as your body.
              </div>
              <p class="uPainTxtHead my-2">5.Track your progress</p>
              <div class="uPainsubTxt my-2">
                Hitting your daily target will feel great! Using tracking apps
                can help, but even just a checklist on a piece of paper will do.
              </div>
              <p class="uPainTxtHead my-2">6.It's better together</p>
              <div class="uPainsubTxt my-2">
                If your friends and family want to be more active too, try
                engaging everyone's competitive side with activity challenges
                like seeing who can do the most steps in a day.
              </div>
              <p class="uPainTxtHead my-2">7.Go from strength to strength</p>
              <div class="uPainsubTxt my-2">
                Strength-building activities – like carrying heavy grocery bags,
                or following an online pilates or strength workout video - help
                to keep muscles, joints and bones strong. Aim to do this at
                least twice a week.
              </div>
              <p class="uPainTxtHead my-2">8.Reward yourself</p>
              <div class="uPainsubTxt my-2">
                Set yourself activity goals and rewards. You could go for a long
                walk then treat yourself to an episode of your favourite TV
                show!
              </div>
              <p class="uPainTxtHead my-2">9.Find something you enjoy</p>
              <div class="uPainsubTxt my-2">
                Not sure where to start? Give one of the offers below a go to
                find something that's right for you, or try searching for an
                online programme. Whether it's running, yoga or simply dancing
                around your living room, it all counts!
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <div>
                <img
                  src="@/assets/img/moving.jpg"
                  class="img-fluid img-border"
                />
              </div>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">
                Home Care For muscle pain from overuse or injury
              </p>
              <div class="uPainsubTxt my-2">
                <ul>
                  <li>
                    Muscle aches from overuse often respond well to massage.
                  </li>
                  <li>
                    Gentle stretching exercises after a long rest period are
                    also helpful.
                  </li>
                  <li>Regular exercise can help restore proper muscle tone.</li>
                  <li>
                    Walking, cycling, and swimming are good aerobic activities
                    to try.
                  </li>
                  <li>
                    A physical therapist can teach you stretching, toning, and
                    aerobic exercises to help you feel better and stay
                    pain-free. Begin slowly and increase workouts gradually.
                    Avoid high-impact aerobic activities and weight lifting when
                    injured or while in pain.
                  </li>
                  <li>
                    Be sure to get plenty of sleep and try to reduce stress.
                    Yoga and meditation are excellent ways to help you sleep and
                    relax.
                  </li>
                  <li>
                    If home measures aren't working, your health care provider
                    may prescribe medicine or physical therapy. You may need to
                    be seen at a specialized pain clinic.
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img
                src="@/assets/img/gym-clothes.jpg"
                class="img-fluid w-75 img-border"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfsideSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">
                These steps may help lower the risk for getting muscle aches:
              </p>
              <div class="uPainsubTxt my-2">
                <ul>
                  <li>Stretch before and after exercising.</li>
                  <li>Warm up before exercising and cool down afterward.</li>
                  <li>
                    Drink lots of fluids before, during, and after exercise.
                  </li>
                  <li>
                    If you work in the same position most of the day (such as
                    sitting at a computer), stretch at least every hour.
                  </li>
                </ul>
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img
                src="@/assets/img/drink.jpg"
                class="img-fluid w-75 img-border"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
    <section class="sideEffects-sec mt-5">
      <v-container class="h-100">
        <div class="conOfstopSec pa-5">
          <v-row class="justify-space-between py-5">
            <v-col md="5" cols="12" class="pa-5 ma-5">
              <p class="uPainTxtHead my-2">
                Prevent Injuries to Muscles and Joints
              </p>
              <div class="uPainsubTxt my-2">
                Longer time spent on physical demands and reduced recovery time
                are associated with working long hours. Working continuously for
                long hours puts you at higher risk for pain or discomfort in
                these body areas:

                <ul>
                  <li>Upper extremities (hands, arms)</li>
                  <li>Lower extremities (feet, knees, hips)</li>
                  <li>Neck, shoulders, and back</li>
                </ul>
              </div>
              <p class="uPainTxtHead my-2">
                Physical demands, such as lifting and working in awkward
                postures, place additional stress on your muscles and joints.
              </p>
              <div class="uPainsubTxt my-2">
                In emergency settings, work stations may be temporary with no
                consideration for ergonomics, leading to awkward postures that
                are fatiguing. Consider establishing preventive measures early
                in the deployment.

                <ul>
                  <li>
                    Modify or redesign tasks, tools, equipment, and your
                    workstation/area if possible.
                  </li>
                  <li>Frequently take a few moments to stretch and move.</li>
                  <li>
                    Vary your posture every hour. Walk around for 5 min after
                    performing a stationary desk task continuously for one hour.
                    While sitting, keep the back and neck straight.
                  </li>
                  <li>Take additional corrective action if symptoms occur.</li>
                </ul>
              </div>
            </v-col>
            <v-col
              md="5"
              cols="12"
              class="d-flex justify-lg-end justify-center"
            >
              <img
                src="@/assets/img/body-pos.jpg"
                class="img-fluid w-75 img-border"
              />
            </v-col>
          </v-row>
        </div>
      </v-container>
    </section>
  </section>
</template>
<script>
export default {
  name: "understandingPain",
  components: {},

  data() {
    return {
      CurrentTab: "Sprays",
      settings: {
        dots: false,
        focusOnSelect: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      settingsReviews: {
        dots: false,
        focusOnSelect: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        touchThreshold: 4,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              initialSlide: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      },
      videoOptions: {
        autoplay: false,
        muted: false,
        controls: true,
        preload: "auto",
        sources: [
          {
            src: "http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4",
            type: "video/mp4",
          },
        ],
      },
      slides: [
        {
          img: require("@/assets/img/Move on freeze spray-min.png"),
          title: "Move on freeze spray",
          dec: "Freeze spray for athletes, fitness enthusiasts, and sporty people who require cooling effect to relieve muscle spasm/ cramps and aches, easy to carry and apply within seconds , provides a highly pressurized force of cooling menthol that quickly numbs the pain, low temperatures immediately relieve swelling and pain",
        },
        {
          img: require("@/assets/img/Move On Freez Gel-min.png"),
          title: "Move On Freeze Gel",
          cream: true,

          dec: "Gel for rapid relief with cooling effect targeting young people who are exposed to accidental injuries and muscle aches",
        },

        {
          img: require("@/assets/img/Move on Spray-min.png"),
          title: "Move on spray",
          dec: "Spray for easy application for those who do not tolerate or do not like rubbing cream, it also provides instant relief, Sprays can be excellent for hard-to-reach spots like your back.",
        },
        {
          img: require("@/assets/img/Move On Cream-min.png"),
          cream: true,
          title: "Move On Cream",
          dec: " Cream is for elderlies suffering from chronic pain and muscle ache ",
        },
        {
          img: require("@/assets/img/Move On Hot Patch-min.png"),
          title: "Move On Hot Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
        {
          img: require("@/assets/img/Move On Freeze Patch-min.png"),
          title: "Move On Freeze Patch",
          dec: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been",
        },
      ],
    };
  },

  methods: {},
  created() {},
};
</script>
<style lang="scss" scoped>
@import "./stopping";
</style>
